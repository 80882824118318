import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class HeadMixin extends Vue {
  // https://stackoverflow.com/questions/50243769/vuetify-how-to-set-background-color
  backgroundStyle = {
    background: this.$vuetify.theme.themes[this.theme].background
  }

  get theme() {
    return this.$vuetify.theme.dark ? 'dark' : 'light'
  }
}
