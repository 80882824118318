import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { PPnote, PPauthUser } from '@/types'

interface PPauthUserState {
  user: PPauthUser
}

export const state = (): PPauthUserState => ({
  user: {
    uid: null,
    displayName: null
  }
})

export const getters: GetterTree<PPauthUserState, PPauthUserState> = {
  uid(state) {
    if (state.user.uid) {
      return state.user.uid
    }
    return null
  },

  user(state) {
    return state.user
  },

  isAuthenticated(state) {
    return !!state.user.uid
  }
}

export const mutations: MutationTree<PPauthUserState> = {
  setUser(state, user: PPauthUser) {
    state.user = { ...state.user, ...user }
  },

  unsetUser(state) {
    state.user = {
      uid: null,
      displayName: null
    }
  }
}

export const actions: ActionTree<PPauthUserState, PPauthUserState> = {
  signIn({ commit }, user: firebase.User) {
    commit('setUser', {
      uid: user.uid,
      displayName: user.isAnonymous ? 'Guest' : user.displayName
    })
  },

  signOut({ commit }) {
    this.$auth
      .signOut()
      .then(() => {
        commit('unsetUser')
      })
      .catch((error) => {
        console.error(error.message)
      })
  },

  async downloadStorage({ getters, dispatch }) {
    const uid = getters.uid
    if (!uid) {
      return
    }

    const storageRef = this.$storage.ref()
    const jsonRef = storageRef.child(`notes/${uid}/pp.json`)

    let url
    try {
      url = await jsonRef.getDownloadURL()
    } catch (error) {
      // no-data
      if (error.code === 'storage/object-not-found') {
        return
      }
      console.error(error)
    }

    try {
      const res = await this.$axios.get(url)
      await dispatch('notes/restore', { notes: res.data }, { root: true })
      // console.log('update from CloudStorage')
    } catch (error) {
      console.error(error)
    }
  },

  async uploadStorage({ getters }, { notes }: { notes: PPnote[] }) {
    const uid = getters.uid
    if (!uid) {
      return
    }

    try {
      const storageRef = this.$storage.ref()
      const jsonRef = storageRef.child(`notes/${uid}/pp.json`)
      await jsonRef.putString(JSON.stringify(notes), 'raw', {
        contentType: 'application/json'
      })
    } catch (error) {
      console.error(error)
    }
  }
}
