import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31847c7e = () => interopDefault(import('../pages/add.vue' /* webpackChunkName: "pages/add" */))
const _0e4f42d2 = () => interopDefault(import('../pages/add_shortcut.vue' /* webpackChunkName: "pages/add_shortcut" */))
const _7f7f97fb = () => interopDefault(import('../pages/chart.vue' /* webpackChunkName: "pages/chart" */))
const _c8ecd9de = () => interopDefault(import('../pages/list.vue' /* webpackChunkName: "pages/list" */))
const _6781f165 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _70433578 = () => interopDefault(import('../pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _fe016882 = () => interopDefault(import('../pages/sign_out.vue' /* webpackChunkName: "pages/sign_out" */))
const _16e278da = () => interopDefault(import('../pages/update/_date/_time/index.vue' /* webpackChunkName: "pages/update/_date/_time/index" */))
const _1dc56222 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add",
    component: _31847c7e,
    name: "add"
  }, {
    path: "/add_shortcut",
    component: _0e4f42d2,
    name: "add_shortcut"
  }, {
    path: "/chart",
    component: _7f7f97fb,
    name: "chart"
  }, {
    path: "/list",
    component: _c8ecd9de,
    name: "list"
  }, {
    path: "/privacy",
    component: _6781f165,
    name: "privacy"
  }, {
    path: "/sign_in",
    component: _70433578,
    name: "sign_in"
  }, {
    path: "/sign_out",
    component: _fe016882,
    name: "sign_out"
  }, {
    path: "/update/:date?/:time?",
    component: _16e278da,
    name: "update-date-time"
  }, {
    path: "/",
    component: _1dc56222,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
