import { GetterTree, ActionTree, MutationTree } from 'vuex'
import merge from 'lodash/merge'
import { PPtype, PPnote, PPnotes } from '@/types'

interface PPnotesState {
  notes: PPnotes
  types: PPtype[]
}

export const state = (): PPnotesState => ({
  notes: {},
  types: [
    { label: 'Poo 😊', value: 1 },
    { label: 'Watery Poo 😱', value: 2 },
    { label: 'Hard Poo 😤', value: 3 },
    { label: 'Pee 💧', value: 4 }
  ]
})

export const getters: GetterTree<PPnotesState, PPnotesState> = {
  note: (state) => (date: string, time: string) => {
    return state.notes[date][time]
  },
  notes(state) {
    return state.notes
  },
  type: (state) => (id: PPtype['value']) => {
    const type = state.types.find((type) => type.value === id)
    if (!type) {
      throw new Error('unexpected type id')
    }
    return type
  },
  types(state) {
    return state.types
  }
}

export const mutations: MutationTree<PPnotesState> = {
  add(state, note: PPnote) {
    if (!state.notes[note.date]) {
      state.notes[note.date] = {}
    }
    state.notes[note.date][note.time] = note
  },
  remove(state, note: PPnote) {
    delete state.notes[note.date][note.time]
    if (Object.keys(state.notes[note.date]).length === 0) {
      delete state.notes[note.date]
    }
  },
  restore(state, notes: PPnotes) {
    const newNotes: PPnotes = merge(state.notes, notes)
    state.notes = { ...{}, ...newNotes }
  }
}

export const actions: ActionTree<PPnotesState, PPnotesState> = {
  async add({ commit, dispatch, getters }, { note }: { note: PPnote }) {
    commit('add', note)

    await dispatch(
      'authUser/uploadStorage',
      { notes: getters.notes },
      { root: true }
    )
  },
  async remove({ commit, dispatch, getters }, { note }: { note: PPnote }) {
    commit('remove', note)

    await dispatch(
      'authUser/uploadStorage',
      { notes: getters.notes },
      { root: true }
    )
  },
  async update({ commit, dispatch, getters }, { oldNote, newNote }) {
    commit('remove', oldNote)
    commit('add', newNote)

    await dispatch(
      'authUser/uploadStorage',
      { notes: getters.notes },
      { root: true }
    )
  },
  restore({ commit }, { notes }: { notes: PPnotes }) {
    commit('restore', notes)
  }
}
