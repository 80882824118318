import firebase from '~/plugins/firebase'

export default (_, inject) => {
  inject('firebase', firebase)
  inject('auth', firebase.auth())
  inject('storage', firebase.storage())
  inject('ga', (event, eventParams, options) => {
    if (process.env.NODE_ENV === 'production') {
      firebase.analytics().logEvent(event, eventParams, options)
    } else {
      console.log('[debug] analytics', event, eventParams, options)
    }
  })
}
