export const SITE_NAME = 'pp-note'
export const DESCRIPTION = 'The easiest way to record your "pp"'
export const SITE_URL = process.env.BASE_URL || 'https://pp-note.web.app'
export const TITLE = `${SITE_NAME}`
export const KEYWORDS =
  'stomach ache, baby, pee, poo, toilet, wc, restroom, ibs'
export const OGIMG_URL = `${SITE_URL}/logo.jpg`
export const TITLE_TEMPLATE = `%s`

export const commonHead = function() {
  return {
    titleTemplate: TITLE_TEMPLATE,
    title: TITLE,
    htmlAttrs: {
      prefix: 'og: http://ogp.me/ns# fb: http://ogp.me/ns/ fb#'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { hid: 'description', name: 'description', content: DESCRIPTION },
      {
        hid: 'keywords',
        name: 'keywords',
        content: KEYWORDS,
        'xml:lang': 'ja',
        lang: 'ja'
      },
      { hid: 'og:site_name', name: 'og:site_name', content: SITE_NAME },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: TITLE },
      { hid: 'og:description', name: 'og:description', content: DESCRIPTION },
      { hid: 'og:image', name: 'og:image', content: OGIMG_URL },
      { hid: 'og:url', name: 'og:url', content: SITE_URL },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'application-name', content: SITE_NAME }
    ],
    link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.png' }]
  }
}
export const commonManifest = function() {
  return {
    name: SITE_NAME,
    lang: 'ja',
    short_name: SITE_NAME,
    title: TITLE,
    description: DESCRIPTION,
    start_url: '/add?utm_source=homescreen',
    display: 'standalone',
    shortcuts: [
      {
        name: 'Poo 😊',
        short_name: 'Poo 😊',
        description: 'Record Poo',
        url: '/add_shortcut?type=1&utm_source=homescreen'
      },
      {
        name: 'Watery Poo 😱',
        short_name: 'Watery Poo 😱',
        description: 'Record Watery Poo',
        url: '/add_shortcut?type=2&utm_source=homescreen'
      },
      {
        name: 'Hard Poo 😤',
        short_name: 'Hard Poo 😤',
        description: 'Record Hard Poo',
        url: '/add_shortcut?type=3&utm_source=homescreen'
      },
      {
        name: 'Pee 💧',
        short_name: 'Pee 💧',
        description: 'Record Pee',
        url: '/add_shortcut?type=4&utm_source=homescreen'
      }
    ]
  }
}
