import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })

type Options = {
  key: string
  paths: string[]
  storage?: {
    getItem: (key: string) => any
    setItem: (key: string, value: any) => void
    removeItem: (key: string) => void
  }
}
const options: Options = {
  key: 'PPStorage',
  paths: ['notes.notes', 'authUser.user']
}
options.storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
  removeItem: (key) => ls.remove(key)
}
export default ({ store }) => {
  createPersistedState(options)(store)
}
