






































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TITLE } from '@/commonHead'
import { PPauthUser } from '@/types'
import LayoutMixin from '@/mixins/LayoutMixin'

@Component({
  mixins: [LayoutMixin],
  computed: {
    ...mapGetters({
      isAuthenticated: 'authUser/isAuthenticated',
      user: 'authUser/user'
    })
  }
})
export default class DefaultLayout extends Vue {
  private isAuthenticated!: () => boolean
  private user!: () => PPauthUser
  drawer = false
  items = [
    {
      icon: 'mdi-format-list-bulleted-square',
      title: 'List',
      to: '/list'
    },
    {
      icon: 'mdi-chart-bar-stacked',
      title: 'Chart',
      to: '/chart'
    },
    {
      icon: 'mdi-plus',
      title: 'Add',
      to: '/add'
    },
    {
      icon: 'mdi-information-outline',
      title: 'Privacy Policy',
      to: '/privacy'
    }
  ]

  title = TITLE
}
